import React from "react"
import { graphql, Link } from "gatsby"
import Masonry from "react-masonry-css"
import Img from "gatsby-image"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"
import Phone from "../components/Phone"
import Mail from "../components/Mail"

import CatFr from "../components/Menu/CatFr"
import Social from "../components/Social"

//css
import "../css/masonry.css"

const commercants = ({
  data: {
    com: { nodes },
  },
}) => {
  const langSelect = [
    { locale: "fr", path: "/commercants" },
    { locale: "nl", path: "/handelaren" },
  ]
  const langCurrent = "fr"

  const myColumn = {
    default: 4,
    1200: 3,
    980: 2,
    740: 2,
    500: 1,
  }
  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section className="main">
        <div className="sidebar">
          <div className="block">
            <h2>Type</h2>
            <CatFr />
          </div>
          <div className="block">
            <Phone />
          </div>
          <div className="block">
            <Mail langCurrent={langCurrent} />
          </div>
          <div className="block">
            <Social langCurrent={langCurrent} />
          </div>
        </div>
        <div className="content">
          <Masonry
            breakpointCols={myColumn}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {nodes.map((item, index) => {
              return (
                <div key={index}>
                  <Link to={`/${langCurrent}/commercants/${item.slug}`}>
                    <Img fluid={item.logo.fluid} alt={item.title} />
                    <h2>{item.title}</h2>

                    <p>{item.subtitle}</p>
                  </Link>
                </div>
              )
            })}
          </Masonry>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query GetAllCommercant {
    com: allContentfulCommercant(
      filter: { node_locale: { eq: "fr" } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        node_locale
        slug
        subtitle
        logo {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`

export default commercants
